import React from "react"
import { v4 as uuidv4 } from "uuid"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const inStac = [
  {
    title: "MANUFACTURING",
    LinkUr: "/manufacturing/",
    content:
      "Making you more profitable. Your work will continue unencumbered when your operational technology and data is secure.",
  },
  {
    title: "FINANCIAL SERVICES",
    LinkUr: "/financial-services/",
    content:
      "Providing layered protection. Our tailored tradecraft fills any gap – for your highly targeted and regulated industry.",
  },
  {
    LinkUr: "/healthcare-services/",
    title: "HEALTHCARE",
    content:
      "You stay on mission: our security sophistication never sleeps so you remain in compliance and your patients remain safe.",
  },
  {
    LinkUr: "/defense/",
    title: "DEFENSE",
    content:
      "Making you more compliant. Our services include ongoing security verification, ensuring attestation with CMMC standards.",
  },
  {
    LinkUr: "/retail/",
    title: "RETAIL",
    content:
      "We fight criminals. By protecting you from brand damage,legal fees and response costs, we keep your sales moving.",
  },
  {
    LinkUr: "/us-public-sector/",
    title: "US PUBLIC",
    content:
      "Protecting people together. When attackers want to hurt the citizenry, they target you. We detect so you can protect.",
  },
]

const RecCard = ({
  altTxt,
  ariaLabel,
  subHeader,
  title,
  content,
  imgSrc,
  hrf,
}) => {
  return (
    <>
      <a href={hrf} itemProp="url" aria-label={ariaLabel} target="_blank" rel="noopener noreferrer">
        <li
          className="client-background"
          key={uuidv4()}
          itemProp="backgroundImage"
        >
          <ul className="client-card">
            <li className="client-img">
              <img
                src={imgSrc}
                quality={95}
                formats={["AUTO", "WEBP"]}
                alt={altTxt}
                className="img-fluid"
              />
            </li>
            <li className="client-inner">
              <ul className="client-base">
                <li className="client-subheader">{subHeader}</li>
                <li className="client-title">{title}</li>
                <li className="client-content">{content}</li>
                <li className="client-cta"></li>
              </ul>
            </li>
          </ul>
        </li>
      </a>
    </>
  )
}

const ProCard = ({ altTxt, ariaLabel, subHeader, title, content, imgSrc }) => {
  return (
    <>
      <li className="card-wrapper" key={uuidv4()} itemProp="cardWrapper">
        <Link to="/" itemProp="url" aria-label={ariaLabel}>
          <ul className="client-card">
            <li className="client-img">
              <img
                src={imgSrc}
                quality={95}
                formats={["AUTO", "WEBP"]}
                alt={altTxt}
                className="img-fluid"
              />
            </li>
            <li className="client-inner">
              <ul className="client-base">
                <li className="client-subheader">{subHeader}</li>
                <li className="client-title">{title}</li>
                <li className="client-content">{content}</li>
                <li className="client-cta"></li>
              </ul>
            </li>
          </ul>
        </Link>
      </li>
    </>
  )
}

const MediaCard = ({ altTxt, title, imgSrc }) => {
  return (
    <>
      <div className="media-card" key={uuidv4()} itemProp="cardWrapper">
        <div className="media-card--img">
          <img src={imgSrc} alt={altTxt} className="img-fluid" itemProp="img" />
        </div>
        <div className="media-card--txt">
          <h3>{title}</h3>
        </div>
      </div>
    </>
  )
}

const ForCard = ({ altTxt, title, imgSrc, content, LinkUrl }) => {
  return (
    <>
      <li className="card" key={uuidv4()} itemProp="cardWrapper">
        <Link to={LinkUrl} alt={title} target="_blank" rel="noopener noreferrer">
          <ul className="feature-card">
            <li className="feature-img">
              <img
                src={imgSrc}
                alt={altTxt}
                quality={95}
                layout="fullWidth"
                formats={["AUTO", "WEBP"]}
                className="img-fluid text-center"
              />
            </li>
            <li className="feature-title">{title}</li>
            <li className="feature-content">{content}</li>
          </ul>
        </Link>
        <div className="d-chase"></div>
      </li>
    </>
  )
}

const ForCardNoLinks = ({ altTxt, title, imgSrc, content, LinkUrl }) => {
  return (
    <>
      <li className="card" key={uuidv4()} itemProp="cardWrapper">
        <ul className="feature-card">
          <li className="feature-img">
            <img
              src={imgSrc}
              alt={altTxt}
              quality={95}
              layout="fullWidth"
              formats={["AUTO", "WEBP"]}
              className="img-fluid text-center"
            />
          </li>
          <li className="feature-title">{title}</li>
          <li className="feature-content">{content}</li>
        </ul>
      </li>
    </>
  )
}

const FooterRecCard = ({ altTxt, title, subTitle, recCat, LinkUrl }) => {
  return (
    <>
      <li className="footer-rec-card" key={uuidv4()} itemProp="cardWrapper">
        <a href={LinkUrl} alt={altTxt} target="_blank" rel="noopener noreferrer">
          <ul className="resources-cards">
            <li className="resources-cat">{recCat}</li>
            <li className="resources-title">
              {title}
              <br />
              {subTitle}
            </li>
          </ul>
        </a>
      </li>
    </>
  )
}

const BlogCard = ({
  altTxt,
  ariaLabel,
  date,
  title,
  content,
  imgSrc,
  slug,
}) => {
  return (
    <>
      <li
        className="client-background"
        key={uuidv4()}
        itemProp="backgroundImage"
      >
        <Link
          to={slug}
          itemProp="url"
          aria-label={ariaLabel}
          state={{ title: title }}
        >
          <ul className="client-card">
            <li className="client-img">
              <img
                src={imgSrc}
                quality={95}
                formats={["AUTO", "WEBP"]}
                alt={altTxt}
                className="img-fluid"
              />
            </li>
            <li className="client-inner">
              <ul className="client-base">
                <li className="client-subheader">{date}</li>
                <li className="client-title text-uppercase">{title}</li>
                <li className="client-content">{content}</li>
                <li className="client-cta"></li>
              </ul>
            </li>
          </ul>
        </Link>
      </li>
    </>
  )
}

const IconRow = ({ iconImg, iconTitle, iconContent }) => {
  return (
    <>
      <li>
        <ul className="icon-list">
          <li className="icon-img">
            <img
              src={iconImg}
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt={iconTitle}
              className="img-fluid"
            />
          </li>
          <li className="icon-title">{iconTitle}</li>
          <li className="icon-hr"></li>
          <li className="icon-content">{iconContent}</li>
        </ul>
      </li>
    </>
  )
}

const VigilantInfographic = () => {
  return (
    <>
      <StaticImage
        src="../images/bg/infographic-home.svg"
        quality={95}
        formats={["AUTO", "WEBP"]}
        layout="fullWidth"
        alt="Vigilant MDR Info Graph"
        className="img-fluid"
      />
    </>
  )
}

const IndustriesDyna = ({ title, content, LinkUrl }) => {
  return (
    <>
      <li>
        <Link to={LinkUrl} alt={title}>
          <ul className="block-industries">
            <li className="block-title">{title}</li>
            <li className="block-content">{content}</li>
          </ul>
        </Link>
      </li>
    </>
  )
}

const IndustriesNoLinks = ({ title, content }) => {
  return (
    <>
      <li>
        <ul className="block-industries">
          <li className="block-title">{title}</li>
          <li className="block-content">{content}</li>
        </ul>
      </li>
    </>
  )
}

const IndustriesStatic = () => {
  return (
    <>
      <ul className="blocks-list">
        {inStac.map(data => {
          return (
            <IndustriesDyna
              LinkUrl={data.LinkUr}
              title={data.title}
              content={data.content}
              key={uuidv4()}
            />
          )
        })}
      </ul>
    </>
  )
}

export {
  RecCard,
  ProCard,
  MediaCard,
  ForCard,
  ForCardNoLinks,
  FooterRecCard,
  IndustriesNoLinks,
  IndustriesStatic,
  IndustriesDyna,
  BlogCard,
  IconRow,
  VigilantInfographic,
}
