import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Navbar from "./navbar"
import FootBar from "./footer"
import "./layout.scss"

const Layout = ({ children }) => {
  /* eslint-disable-next-line */
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <Navbar siteTitle={`Title`} />
    <div className="container-fluid p-0">
      <main>
        {children}
      </main>
      <FootBar siteTitle={`Title`} />
    </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
