import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { VigilantInfographic } from "../components/resources"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/mdr-services/",
    label: "MDR",
  },
]

const MdrService = () => (
  <Layout>
    <Seo title="MDR SERVICE" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <section className="section-title-top--mdr-services grid--bg --for-it-ops-1">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h1>
              VigilantMDR <span>SERVICE</span>{" "}
            </h1>
            <p className="content">
              With Vigilant Managed Detection and Response the operative word is
              “managed,” meaning that our advanced technologies are always
              backed by battle-tested human intelligence. We are so confident in
              our approach to VigilantMDR services that we back them with our
              Unlimited Breach Response warranty.
            </p>
            <Link to="/contact/" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  become attack resilient
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>
            </Link>
          </div>
          <div className="section-col-2"></div>
        </div>
      </div>
    </section>
    <section className="section-col-2-img--mdr-services">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>
              VigilantMDR ingests live data across your integration points
              including - network, endpoints and email.
            </h3>
          </div>
          <div className="section-col-2">
            <p className="content pt-4">
              This industry-leading wealth of data enters our Adaptive
              Intelligence Process™ to detect, analyze and provide intel to you
              and your team. Through this collaborative process we confirm
              threats faster and pivot quickly to an Attack Impact Resiliance™
              stance, providing guided response and confirmed remediation.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="mt-5 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <VigilantInfographic />
          </div>
        </div>
      </div>
    </section>
    <section className="section-slider">
      <div className="section-col-slide">
        <div className="section-col-1">
          <h3>VigilantMNDR</h3>
          <a
            href="https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf"
            className="nav-section--cta-btn"
            target="__blank"
          >
            <div className="d-flex align-items-center">
              <span
                style={{ marginLeft: "25px" }}
                className="nav-section--cta-title pr-5"
              >
                learn more
              </span>
              <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
            </div>
          </a>
        </div>
        <div className="section-col-2">
          <p className="content">
            Vigilant Managed Network Detection and Response is a patented and
            fully managed service that includes the tech, the experts and the
            process to keep your network safe by finding attackers in your
            environment where they would otherwise avoid detection.
          </p>
          <p className="content">
            By placing invisible sensors inside your network, Vigilant is able
            to collect trustworthy and uncompromised source data and, as a
            result, threats are identified exponentially faster and eliminated
            before any data is compromised.
          </p>
          <p className="content">
            Our advanced sensor technology works in tandem with a 24/7 network
            of cyber security experts to deliver the intelligence you need to
            detect and eliminate threats before they infiltrate your
            infrastructure.
          </p>
        </div>
      </div>

      <div className="section-col-slide">
        <div className="section-col-1">
          <h3>VigilantMEDR</h3>
          <a
            href="https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf"
            className="nav-section--cta-btn"
            target="__blank"
          >
            <div className="d-flex align-items-center">
              <span
                style={{ marginLeft: "25px" }}
                className="nav-section--cta-title pr-5"
              >
                learn more
              </span>
              <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
            </div>{" "}
          </a>
        </div>
        <div className="section-col-2">
          <p className="content">
            Vigilant Managed Endpoint Detection and Response protects your
            assets in the cloud, at home, on-prem and everywhere in between. It
            sets a new standard in stopping breaches with unparalleled real-time
            and historical visibility across endpoint events and activities.
          </p>
          <p className="content">
            Through our unique combination of market leading technology and
            Vigilant SOC processes, VigilantMEDR offers a strong, intelligent,
            and complete approach to endpoint security, so you can protect your
            systems and simplify your environment without the need of multiple
            vendors or point products. All of these activities are made possible
            by the collaborative cybersecurity framework in which we work
            shoulder-to-shoulder with your team.
          </p>
        </div>
      </div>

      <div className="section-col-slide">
        <div className="section-col-1">
          <h3>Vigilant365™</h3>
          <a
            href="https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf"
            className="nav-section--cta-btn"
            target="__blank"
          >
            <div className="d-flex align-items-center">
              <span
                style={{ marginLeft: "25px" }}
                className="nav-section--cta-title pr-5"
              >
                learn more
              </span>
              <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
            </div>{" "}
          </a>
        </div>
        <div className="section-col-2">
          <p className="content">
            Vigilant365™ cuts through the extraneous information provided by
            traditional Microsoft 365 monitoring solutions and intelligently
            alerts you to potential account takeovers. Vigilant365™ helps you
            monitor, aggregate and analyze key information indicating potential
            threat activity across your Microsoft 365 environment.
          </p>
          <p className="content">
            Vigilant365™ is different than a spam filter. It intelligently
            alerts on potential email compromise and account takeover. By adding
            another layer of detection beyond network and endpoint, Vigilant365™
            monitors threat activity at the account level to help you guard
            against the data and revenue loss that malicious attacks can cause.
          </p>
        </div>
      </div>
    </section>
    <section className="section-client-quote">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <h3>TRY VIGILIANT FOR YOURSELF</h3>
              <hr />
              <Link
                to="/security-threat-audit/"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    get started today
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default MdrService
